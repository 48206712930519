import styled, { keyframes } from 'styled-components';
import PropTypes from 'prop-types';

// example
// https://codesandbox.io/p/sandbox/custom-react-popup-animte-e43p35?file=%2Fsrc%2FPopup.jsx%3A23%2C11-28%2C42

const Wrapper = styled.div`
	position: fixed;
	background: rgba(0, 0, 0, 0.6);
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 10;
`;

const popupAnimation = keyframes`
0% {
	transform: translateY(30%) scale(0);
	opacity: 0;
}
100% {
	transform: translateY(0%) scale(1);
	opacity: 1;
}
`;
const PopupContent = styled.div`
	position: 'relative';
	animation-name: ${popupAnimation};
	animation-duration: 0.5s;
	animation-timing-function: linear;
	max-width: 90%;
`;

export const PopupAnimated = ({ closePopup, PopupElement }) => {
	return (
		<Wrapper onClick={closePopup}>
			<PopupContent onClick={(e) => e.stopPropagation()}>{PopupElement}</PopupContent>
		</Wrapper>
	);
};

PopupAnimated.propTypes = {
	PopupElement: PropTypes.element,
	closePopup: PropTypes.func,
};
