export const COLORS = {
	grey: '#595959',
	lightGreen: '#92c54f',
	darkGreen: '#4c9727',
	slateBlue: '#475993',
	cadetBlue: '#76cddd',
	bgBlue: '#77cedd',
	orange: '#fe9d17',
	darkOrange: '#fe7317',
};
