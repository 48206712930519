import { SvgIcon } from 'components';
import { withTheme } from 'styled-components';
import { titles } from 'texts';
import { ReactComponent as MailIcon } from 'images/svgIcons/mail-icon.svg';
import { ReactComponent as PhoneIcon } from 'images/svgIcons/phone-icon.svg';
import { ReactComponent as DownloadIcon } from 'images/svgIcons/download-icon.svg';
import { CV } from 'images/certificates';

const headerLinks = [
	{ href: `mailto:${titles.email}`, icon: MailIcon },
	{ href: `tel:${titles.phoneNumber}`, icon: PhoneIcon },
	{ href: CV, icon: DownloadIcon, target: '_blanc' },
];

const HeaderIconLinks = () => (
	<div>
		{headerLinks.map(({ href, icon, target = '_self' }) => (
			<a href={href} key={href} target={target} rel="noreferrer">
				<SvgIcon
					Icon={icon}
					fill="#475993"
					hoverFill="#475993"
					width="28px"
					height="28px"
				/>
			</a>
		))}
	</div>
);

export default withTheme(HeaderIconLinks);
