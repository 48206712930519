import {
	Mount1,
	Mount2,
	Mount3,
	Mount4,
	Mount5,
	Mount6,
	Mount7,
	Mount8,
	Mount9,
	Mount10,
	Mount11,
	Mount12,
	Mount13,
	Mount14,
	Mount15,
	Mount16,
	Mount17,
	Mount18,
	Mount19,
	Mount20,
	Mount21,
	Mount22,
} from 'images/mountains/jpg';
import {
	wpMount1,
	wpMount2,
	wpMount3,
	wpMount4,
	wpMount5,
	wpMount6,
	wpMount7,
	wpMount8,
	wpMount9,
	wpMount10,
	wpMount11,
	wpMount12,
	wpMount13,
	wpMount14,
	wpMount15,
	wpMount16,
	wpMount17,
	wpMount18,
	wpMount19,
	wpMount20,
	wpMount21,
	wpMount22,
} from 'images/mountains/webp';

export const mountainSlides = [
	{ title: 'Kriváň', subTitle: 'Slovakia', src: Mount1, srcWebp: wpMount1 },
	{ title: 'Olympus Biosphere Reserve', subTitle: 'Greece', src: Mount2, srcWebp: wpMount2 },
	{ title: 'High Tatras', subTitle: 'Slovakia', src: Mount3, srcWebp: wpMount3 },
	{ title: 'Carpathians', subTitle: 'Ukraine', src: Mount4, srcWebp: wpMount4 },
	{ title: 'With Mitikas in the background', subTitle: 'Greece', src: Mount5, srcWebp: wpMount5 },
	{ title: 'Carpathians', subTitle: 'Ukraine', src: Mount6, srcWebp: wpMount6 },
	{ title: 'High Tatras', subTitle: 'Slovakia', src: Mount7, srcWebp: wpMount7 },
	{ title: 'Ushguli', subTitle: 'Georgia', src: Mount8, srcWebp: wpMount8 },
	{ title: 'Nesamovyte Lake', subTitle: 'Ukraine', src: Mount9, srcWebp: wpMount9 },
	{
		title: 'Last metres to Skolio Peak',
		subTitle: 'Olympus, Greece',
		src: Mount10,
		srcWebp: wpMount10,
	},
	{ title: 'High Tatras', subTitle: 'Slovakia', src: Mount11, srcWebp: wpMount11 },
	{ title: 'Carpathians', subTitle: 'Ukraine', src: Mount12, srcWebp: wpMount12 },
	{ title: 'Svaneti', subTitle: 'Georgia', src: Mount13, srcWebp: wpMount13 },
	{
		title: 'Putting down our names at Skolio Peak',
		subTitle: 'Olympus, Greece',
		src: Mount14,
		srcWebp: wpMount14,
	},
	{ title: 'High Tatras', subTitle: 'Slovakia', src: Mount15, srcWebp: wpMount15 },
	{ title: 'Stepantsminda', subTitle: 'Georgia', src: Mount16, srcWebp: wpMount16 },
	{ title: 'Kriváň', subTitle: 'Slovakia', src: Mount17, srcWebp: wpMount17 },
	{ title: 'Skolio Peak', subTitle: 'Olympus, Greece', src: Mount18, srcWebp: wpMount18 },
	{ title: 'High Tatras', subTitle: 'Slovakia', src: Mount19, srcWebp: wpMount19 },
	{ title: 'Pip Ivan View', subTitle: 'Carpathians, Ukraine', src: Mount20, srcWebp: wpMount20 },
	{ title: 'High Tatras', subTitle: 'Slovakia', src: Mount21, srcWebp: wpMount21 },
	{ title: 'Olympus', subTitle: 'Greece', src: Mount22, srcWebp: wpMount22 },
];
