import { Fragment, useState } from 'react';
import styled from 'styled-components';
import { Breakpoint } from 'react-socks';
import { summary as summaryData, titles } from 'texts';
import { PopupAnimated, LocationText } from 'components';

const Wrapper = styled.div`
	text-align: center;
	padding: 60px 20px;
`;

const Heading = styled.div`
	font-family: 'RupsterScript';
	color: ${(props) => props.theme.colors.slateBlue};
	line-height: 3.5em;
	padding-bottom: 60px;
`;

const Title = styled.h1`
	font-size: 36px;
`;

const Subject = styled.h2`
	font-size: 30px;
`;

const SummaryText = styled.div`
	font-size: 14px;
	color: ${(props) => props.theme.colors.grey};
	text-align: center;
	padding: 0 20px;
	max-width: 800px;
	margin: 0 auto;
	user-select: none;
	@media (min-width: 769px) {
		font-size: 16px;
	}
`;

const SummaryParagraph = styled.p`
	margin-bottom: 15px;
`;

const LocationUrl = styled.a`
	color: ${(props) => props.theme.colors.grey};
	font-family: 'ProximaNovaBold';
`;

export const Summary = () => {
	const [isOpenedPopup, setIsOpenedPopup] = useState(false);

	const { name, role } = titles;
	const { summaryCommon, summaryDesktop } = summaryData;

	return (
		<Fragment>
			<Wrapper>
				<Heading>
					<Title href="#">{name}</Title>
					<Subject>{role}</Subject>
				</Heading>
				<SummaryText>
					{summaryCommon.map((x, i) => {
						const text = x.replace(/<[^>]*>/g, ' ');
						const parts = i === 0 ? text.split('Kharkiv, Ukraine') : null;

						return (
							<SummaryParagraph key={x.slice(0, 20)}>
								{parts ?
									<Fragment>
										<span>{parts[0]}</span>
										<LocationUrl
											href="#"
											onClick={() => setIsOpenedPopup(true)}
										>
											Kharkiv, Ukraine*
										</LocationUrl>
										<span>{parts[1]}</span>
									</Fragment>
								:	text}
							</SummaryParagraph>
						);
					})}
					<Breakpoint medium up>
						<SummaryParagraph>{summaryDesktop}</SummaryParagraph>
					</Breakpoint>
				</SummaryText>
			</Wrapper>
			{isOpenedPopup && (
				<PopupAnimated
					closePopup={() => setIsOpenedPopup(false)}
					PopupElement={<LocationText onClose={() => setIsOpenedPopup(false)} />}
				/>
			)}
		</Fragment>
	);
};
