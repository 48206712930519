import styled, { withTheme } from 'styled-components';
import PropTypes from 'prop-types';
import { themePropTypes } from 'propTypes';
import { ReactComponent as MenuIcon } from 'images/svgIcons/menu-icon.svg';
import { ReactComponent as CloseIcon } from 'images/svgIcons/close-circle.svg';
import { SvgIcon, HeaderIconLinks } from 'components';

const HeaderMobileWrapper = styled.header`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	padding: 20px;
	background-color: ${(props) => props.theme.colors.lightGreen};
`;

const HeaderRight = styled.div`
	&:hover {
		cursor: pointer;
	}
`;

const Button = styled.button`
	border: none;
	background-color: transparent;
	cursor: pointer;
`;

const HeaderMobile = ({ theme, handleOpenMenu, isMenuOpened }) => {
	return (
		<HeaderMobileWrapper>
			<HeaderIconLinks />
			<HeaderRight>
				{isMenuOpened ?
					<Button onClick={() => handleOpenMenu(false)}>
						<SvgIcon
							Icon={CloseIcon}
							fill={theme.colors.slateBlue}
							hoverFill={theme.colors.slateBlue}
							width="30px"
							height="30px"
						/>
					</Button>
				:	<Button onClick={() => handleOpenMenu(true)}>
						<SvgIcon
							Icon={MenuIcon}
							fill={theme.colors.slateBlue}
							hoverFill={theme.colors.slateBlue}
							width="28px"
							height="28px"
						/>
					</Button>
				}
			</HeaderRight>
		</HeaderMobileWrapper>
	);
};

export default withTheme(HeaderMobile);

HeaderMobile.propTypes = {
	theme: themePropTypes,
	handleOpenMenu: PropTypes.func,
	isMenuOpened: PropTypes.bool,
};
