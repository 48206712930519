import styled from 'styled-components';
import { SectionTitle, ImgPopupElement } from 'components';
import { titles, otherDescription } from 'texts';
import { NextJSCertificate, NodeJSCertificate } from 'images/certificates';

const Wrapper = styled.div`
	padding: 60px 20px;
	color: ${(props) => props.theme.colors.grey};
	max-width: 800px;
	margin: 0 auto;
`;

const Content = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	@media (max-width: 678px) {
		gap: 0;
	}
`;

const Block = styled.div`
	padding: 0 20px 30px 0;
	> a {
		color: ${(props) => props.theme.colors.grey};
		display: block;
	}
`;

const Title = styled.h3`
	text-transform: uppercase;
`;

const ImgBox = styled.div`
	padding-top: 20px;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	gap: 20px;
	> img {
		width: 200px;
		&:hover {
			cursor: pointer;
		}
	}
`;

const MailLink = styled.a`
	font-family: 'ProximaNovaBold';
`;

export const Other = () => {
	const { otherTitle, eduTitle, email } = titles;
	const { university, major, years, certificates, engLevelTitle, engLevel, contactTitle } =
		otherDescription;

	return (
		<Wrapper>
			<SectionTitle title={otherTitle} />
			<Content>
				<Block>
					<Title>{eduTitle}</Title>
					<h4>{university}</h4>
					<p>{major}</p>
					<p>{years}</p>
				</Block>
				<div>
					<Block>
						<Title>{engLevelTitle}</Title>
						<p>{engLevel}</p>
					</Block>
					<Block>
						<Title>{contactTitle}</Title>
						<MailLink href={`mailto:${email}`}>{email}</MailLink>
						<a href={`tel:${titles.phoneNumber}`}>{titles.phoneNumber}</a>
					</Block>
				</div>
				<Block>
					<Title>{certificates}</Title>
					<ImgBox>
						<ImgPopupElement src={NodeJSCertificate} />
						<ImgPopupElement src={NextJSCertificate} />
					</ImgBox>
				</Block>
			</Content>
		</Wrapper>
	);
};
