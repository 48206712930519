import { Fragment } from 'react';
import { Breakpoint } from 'react-socks';
import {
	ProjectsMobile,
	ProjectsCarouselWrapper,
	ProjectsGrid,
	ProjectsSlideUpWrapper,
} from 'components';

export const ProjectsLayout = () => {
	return (
		<Fragment>
			<Breakpoint small only>
				<ProjectsSlideUpWrapper ProjectsElement={ProjectsMobile} />
			</Breakpoint>
			<Breakpoint medium only>
				<ProjectsSlideUpWrapper ProjectsElement={ProjectsGrid} />
			</Breakpoint>
			<Breakpoint large only>
				<ProjectsCarouselWrapper ProjectsElement={ProjectsGrid} />
			</Breakpoint>
		</Fragment>
	);
};
