import PropTypes from 'prop-types';
import styled from 'styled-components';
import Marquee from 'react-fast-marquee';
import { titles } from 'texts';

const Wrapper = styled.div`
	height: 400px;
	padding: 60px 0;
	box-sizing: border-box;
`;

const Item = styled.div`
	height: 250px;
	color: white;
	position: relative;
	overflow: hidden;
`;

const Title = styled.h4`
	position: absolute;
	bottom: 40px;
	padding: 0 20px;
`;

const SubTitle = styled.h5`
	position: absolute;
	bottom: 20px;
	padding: 0 20px;
`;

export const AboutMarquee = ({ slides }) => {
	return (
		<Wrapper>
			<Marquee>
				{slides.map(({ title = '', subTitle = '', src, srcWebp }, i) => (
					<Item key={title + i}>
						<picture>
							<source srcSet={srcWebp} height="250" type="image/webp" />
							<img src={src} alt={title || titles.imgAlt} />
						</picture>
						<Title>{title}</Title>
						<SubTitle>{subTitle}</SubTitle>
					</Item>
				))}
			</Marquee>
		</Wrapper>
	);
};

AboutMarquee.propTypes = {
	slides: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string,
			subTitle: PropTypes.string,
			src: PropTypes.node.isRequired,
			srcWebp: PropTypes.node.isRequired,
		})
	),
};
