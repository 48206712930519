import { Fragment } from 'react';
import styled, { withTheme } from 'styled-components';
import PropTypes from 'prop-types';
import { ReactComponent as CheckIcon } from 'images/svgIcons/check-square.svg';
import { SvgIcon, CloseButton } from 'components';
import { titles } from 'texts';
import { themePropTypes } from 'propTypes';

const Description = styled.div`
	background-color: #fff;
	color: ${(props) => props.theme.colors.grey};
	box-shadow: 0px -1px 3px gray;
`;

const Title = styled.h2`
	color: ${(props) => props.theme.colors.cadetBlue};
	text-transform: uppercase;
	font-family: 'ProximaNovaLignt';
	font-size: 36px;
	margin-bottom: 15px;
`;

const SubTitle = styled.h3`
	font-family: 'ProximaNovaBold';
	margin: 15px 0;
`;

const Paragraph = styled.p`
	font-size: 16px;
	margin-bottom: 1em;
`;

const List = styled.ul`
	padding-bottom: 15px;
`;

const ListItem = styled.li`
	font-size: 14px;
	position: relative;
`;

const ListItemText = styled.span`
	padding-left: 4px;
	position: relative;
	bottom: 1px;
`;

const ProjectDescription = ({
	title,
	paragraphs,
	technologies,
	siteLink,
	siteName,
	theme,
	wrapperStyle = {},
	onClose,
}) => (
	<Fragment>
		<Description style={wrapperStyle}>
			<Title>{title}</Title>
			{paragraphs.map((p) => (
				<Paragraph key={p.slice(0, 100)}>{p}</Paragraph>
			))}
			<SubTitle>{titles.technologiesSubtitle}</SubTitle>
			<List>
				{technologies.map((x) => (
					<ListItem key={x}>
						<SvgIcon
							Icon={CheckIcon}
							fill={theme.colors.cadetBlue}
							hoverFill={theme.colors.cadetBlue}
							width="12px"
							height="12px"
						/>
						<ListItemText>{x}</ListItemText>
					</ListItem>
				))}
			</List>
			{siteLink && (
				<Paragraph>
					{titles.linkToSiteTitle}
					<a className="slide-link" href={siteLink} target="_blank" rel="noreferrer">
						{siteName}
					</a>
				</Paragraph>
			)}
		</Description>
		<CloseButton onClose={onClose} />
	</Fragment>
);

export default withTheme(ProjectDescription);

ProjectDescription.propTypes = {
	title: PropTypes.string,
	paragraphs: PropTypes.arrayOf(PropTypes.string),
	technologies: PropTypes.arrayOf(PropTypes.string),
	siteLink: PropTypes.string,
	siteName: PropTypes.string,
	theme: themePropTypes,
	wrapperStyle: PropTypes.object,
	onClose: PropTypes.func,
};
