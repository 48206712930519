import styled, { withTheme } from 'styled-components';
import PropTypes from 'prop-types';
import { themePropTypes } from 'propTypes';
import { useWindowSize } from 'hooks';
import { ReactComponent as PrevIcon } from 'images/svgIcons/icon-prev.svg';
import { ReactComponent as NextIcon } from 'images/svgIcons/icon-next.svg';
import { ProjectDescription, SvgIcon } from 'components';

const SlideOuter = styled.div`
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	position: absolute;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 50;
`;

const SlideWrapper = styled.div`
	position: fixed;
	width: 1180px;
	margin-left: -590px;
	left: 50%;
	top: 50%;
	border: 2px solid #fff;
	border-radius: 5px;
	color: #858282;
	transition-property: all;
	transition-duration: 2s;
	background-position: top center;
	background-size: cover;
`;

const Button = styled.button`
	border: none;
	background-color: transparent;
	cursor: pointer;
	position: absolute;
	top: 50%;
	box-shadow: 1px 1px 2px gray;
	border-radius: 50%;
	width: 30px;
	height: 30px;
`;

const SlideModal = ({ onClose, onClickNext, onClickPrev, slideData, theme }) => {
	const { width, height } = useWindowSize();

	const { bgImg, ...rest } = slideData;

	return (
		<SlideOuter onClick={onClose}>
			<SlideWrapper
				onClick={(e) => e.stopPropagation()}
				style={{
					height: `${height - 20}px`,
					marginTop: `-${height / 2 - 10}px`,
					backgroundImage: `url(${bgImg})`,
				}}
			>
				<ProjectDescription
					{...rest}
					wrapperStyle={{
						position: 'absolute',
						bottom: 0,
						left: 0,
						right: 0,
						borderRadius: 5,
						padding: '30px 60px 40px',
					}}
					onClose={onClose}
				/>
				<Button
					onClick={onClickPrev}
					style={{ left: width < 1300 ? 10 : -(width - 1200) / 3 }}
				>
					<SvgIcon
						Icon={PrevIcon}
						fill={theme.colors.cadetBlue}
						hoverFill={theme.colors.orange}
						width="30px"
						height="30px"
					/>
				</Button>
				<Button
					onClick={onClickNext}
					style={{ right: width < 1300 ? 10 : -(width - 1200) / 3 }}
				>
					<SvgIcon
						Icon={NextIcon}
						fill={theme.colors.cadetBlue}
						hoverFill={theme.colors.orange}
						width="30px"
						height="30px"
					/>
				</Button>
			</SlideWrapper>
		</SlideOuter>
	);
};

export default withTheme(SlideModal);

SlideModal.propTypes = {
	onClose: PropTypes.func,
	onClickNext: PropTypes.func,
	onClickPrev: PropTypes.func,
	slideData: PropTypes.shape({
		bgImg: PropTypes.node,
	}),
	theme: themePropTypes,
};
