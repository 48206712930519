import styled from 'styled-components';
import { CV } from 'images/certificates';
import { DownloadBtn } from 'images';
import { titles } from 'texts';

const CVWrapper = styled.div`
	flex: 0 0 260px;
	font-size: 22px;
	padding-top: 7px;
	max-height: 80px;
`;

const CVLink = styled.a`
	color: #fff;
	text-decoration: none;
	display: block;
	padding: 8px 15px;
	background-color: ${(props) => props.theme.colors.orange};
	border-bottom: 3px solid ${(props) => props.theme.colors.darkOrange};
	border-radius: 5px;
	position: relative;
	text-shadow: 0 1px 1px ${(props) => props.theme.colors.darkOrange};
	width: 180px;
	text-align: center;
	&::before {
		content: '';
		background-image: url(${DownloadBtn});
		background-repeat: no-repeat;
		display: inline-block;
		width: 17px;
		height: 26px;
		margin-right: 10px;
		position: relative;
		top: 5px;
	}
	&:hover {
		box-shadow: 0 1px 4px gray;
	}
`;

export const CVBtnFooter = () => (
	<CVWrapper>
		<CVLink href={CV} target="_blank">
			{titles.downloadCVBtn}
		</CVLink>
	</CVWrapper>
);
