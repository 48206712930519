import styled from 'styled-components';
import { titles } from 'texts';
import PropTypes from 'prop-types';
import { AnimatedWrapper } from 'components';

const Menu = styled.nav`
	background-color: ${(props) => props.theme.colors.darkGreen};
	height: 100vh;
	position: absolute;
	top: 76px;
	width: 100%;
`;

const MenuList = styled.ul`
	text-align: center;
	padding-top: 120px;
`;

const MenuItem = styled.li`
	text-transform: uppercase;
	font-size: 32px;
	font-family: ProximaNovaBold;
	color: #fff;
	line-height: 2em;
	cursor: pointer;
	display: block;
	&:hover {
		background-color: ${(props) => props.theme.colors.lightGreen};
	}
`;

const { homeTitle, skillsTitle, projectsTitleMobile, aboutTitle, otherTitle } = titles;

const menuConfig = [
	{ sectionId: 'top', sectionTitle: homeTitle },
	{ sectionId: 'skills', sectionTitle: skillsTitle },
	{ sectionId: 'projects', sectionTitle: projectsTitleMobile },
	{ sectionId: 'about', sectionTitle: aboutTitle },
	{ sectionId: 'other', sectionTitle: otherTitle },
];

export const MenuMobile = ({ handleScroll, handleCloseMenu }) => {
	const handleClickMenuItem = (sectionName) => {
		handleScroll(sectionName);
		handleCloseMenu(false);
	};

	return (
		<AnimatedWrapper>
			<Menu>
				<MenuList>
					{menuConfig.map(({ sectionId, sectionTitle }) => (
						<MenuItem key={sectionId} onClick={() => handleClickMenuItem(sectionId)}>
							{sectionTitle}
						</MenuItem>
					))}
				</MenuList>
			</Menu>
		</AnimatedWrapper>
	);
};

MenuMobile.propTypes = {
	handleScroll: PropTypes.func.isRequired,
	handleCloseMenu: PropTypes.func.isRequired,
};
