import styled from 'styled-components';
import { Fragment, useState } from 'react';
import { PopupAnimated } from 'components';
import { Arrow, SummaryBg, Hobbies, HobbiesBgHover } from 'images';
import { titles, summary } from 'texts';
import { balloonsConfig } from 'configs';

const AboutWrapper = styled.div`
	flex: 0 0 33.33%;
	order: 2;
	position: relative;
	max-height: 660px;
`;

const Summary = styled.div`
	font-size: 12px;
	color: ${(props) => props.theme.colors.grey};
	text-align: center;
	background-image: url(${SummaryBg});
	width: 347px;
	height: 251px;
	padding: 60px 20px 0;
`;

const SummaryParagraph = styled.p`
	margin-bottom: 15px;
`;

const HobbiesWrapper = styled.div`
	position: relative;
	bottom: 75px;
`;

const BottomTitle = styled.div`
	text-align: left;
	padding-left: 80px;
	position: relative;
	bottom: 20px;
	font-family: 'RupsterScript';
	font-size: 26px;
	color: #fff;
	text-align: center;
	&::after {
		content: '';
		background-image: url(${Arrow});
		width: 52px;
		height: 49px;
		display: inline-block;
	}
	&:hover {
		text-shadow: 0 1px 1px #000;
	}
`;

const HobbiesList = styled.ul`
	background-image: url(${Hobbies});
	background-repeat: no-repeat;
	background-position: bottom left;
	padding-left: 135px;
	height: 375px;
	position: relative;
	&:hover {
		background-image: url(${HobbiesBgHover});
	}
`;

const HobbiesItem = styled.li`
	display: inline-block;
	background-repeat: no-repeat;
	position: relative;
	cursor: pointer;
`;

const BalloonImg = styled.img`
	opacity: 0;
	&:hover {
		opacity: 1;
		transition: ease-out 0.75s;
	}
`;

export const AboutFunny = () => {
	const [isOpenedPopup, setIsOpenedPopup] = useState(false);
	const [popupUrl, setPopupUrl] = useState(null);
	return (
		<Fragment>
			<AboutWrapper>
				<Summary>
					{summary.summaryCommon.map((x) => (
						<SummaryParagraph
							key={x.slice(0, 20)}
							dangerouslySetInnerHTML={{ __html: x }}
						/>
					))}
				</Summary>
				<HobbiesWrapper>
					<HobbiesList>
						{balloonsConfig.map(({ key, src, style }) => (
							<HobbiesItem
								key={key}
								style={style}
								onClick={() => {
									setIsOpenedPopup(true);
									setPopupUrl(src);
								}}
							>
								<BalloonImg src={src} />
							</HobbiesItem>
						))}
					</HobbiesList>
					<BottomTitle>{titles.meTitle}</BottomTitle>
				</HobbiesWrapper>
			</AboutWrapper>
			{isOpenedPopup && (
				<PopupAnimated
					closePopup={() => setIsOpenedPopup(false)}
					PopupElement={<img src={popupUrl} alt={titles.imgAlt} />}
				/>
			)}
		</Fragment>
	);
};
