import styled from 'styled-components';
import { titles } from 'texts';
import PropTypes from 'prop-types';

const MenuWrapper = styled.nav``;

const Menu = styled.ul`
	display: flex;
	gap: 20px;
`;

const MenuItem = styled.li`
	text-transform: uppercase;
	font-size: 16px;
	font-family: ProximaNovaBold;
	color: ${(props) => props.theme.colors.slateBlue};
	line-height: 2em;
	cursor: pointer;
	display: block;
`;
export const MenuRegular = ({ handleScroll }) => {
	const handleClickMenuItem = (sectionName) => {
		handleScroll(sectionName);
	};

	const { homeTitle, skillsTitle, projectsTitleMobile, aboutTitle, otherTitle } = titles;

	const menuItems = [
		{ title: homeTitle, value: 'top' },
		{ title: skillsTitle, value: 'skills' },
		{ title: projectsTitleMobile, value: 'projects' },
		{ title: aboutTitle, value: 'about' },
		{ title: otherTitle, value: 'other' },
	];

	return (
		<MenuWrapper>
			<Menu>
				{menuItems.map(({ title, value }) => (
					<MenuItem key={value} onClick={() => handleClickMenuItem(value)}>
						{title}
					</MenuItem>
				))}
			</Menu>
		</MenuWrapper>
	);
};

MenuRegular.propTypes = {
	handleScroll: PropTypes.func.isRequired,
};
