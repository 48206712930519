import {
	Bike,
	Trees,
	Yoga,
	Mountains,
	Traveling,
	Family,
	GreenBalloon,
	RedBalloon,
	PurpleBalloon,
	BlueBalloon,
	YellowBalloon,
	OrangeBalloon,
} from 'images';

export const balloonsConfig = [
	{
		key: 'bike',
		src: Bike,
		style: {
			backgroundImage: `url(${GreenBalloon})`,
			width: '98px',
			height: '95px',
			top: '50px',
			right: '15px',
		},
	},
	{
		key: 'trees',
		src: Trees,
		style: {
			backgroundImage: `url(${RedBalloon})`,
			width: '85px',
			height: '95px',
			right: '31px',
			bottom: '6px',
		},
	},
	{
		key: 'yoga',
		src: Yoga,
		style: {
			backgroundImage: `url(${PurpleBalloon})`,
			width: 97,
			height: 97,
			bottom: 50,
			left: 95,
			zIndex: 3,
		},
	},
	{
		key: 'mountains',
		src: Mountains,
		style: {
			backgroundImage: `url(${BlueBalloon})`,
			width: '96px',
			height: '101px',
			right: '103px',
			bottom: '5px',
			zIndex: '3',
		},
	},
	{
		key: 'traveling',
		src: Traveling,
		style: {
			backgroundImage: `url(${YellowBalloon})`,
			width: '85px',
			height: '91px',
			bottom: '86px',
			left: '85px',
		},
	},
	{
		key: 'family',
		src: Family,
		style: {
			backgroundImage: `url(${OrangeBalloon})`,
			width: '79px',
			height: '84px',
			bottom: '123px',
			left: '75px',
		},
	},
];
