import { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { PopupAnimated } from 'components';
import { useWindowSize } from 'hooks';
import { titles } from 'texts';

export const ImgPopupElement = ({ src }) => {
	const [isOpenedPopup, setIsOpenedPopup] = useState(false);
	const { height } = useWindowSize();

	return (
		<Fragment>
			<img src={src} onClick={() => setIsOpenedPopup(true)} alt={titles.imgAlt} />
			{isOpenedPopup && (
				<PopupAnimated
					closePopup={() => setIsOpenedPopup(false)}
					PopupElement={
						<img src={src} style={{ maxHeight: 0.8 * height }} alt={titles.imgAlt} />
					}
				/>
			)}
		</Fragment>
	);
};

ImgPopupElement.propTypes = {
	src: PropTypes.node.isRequired,
};
