import styled from 'styled-components';
import { SectionTitle } from 'components';
import { titles } from 'texts';
import { skillItems } from 'configs';

const SkillsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: ${(props) => props.theme.colors.darkGreen};
	padding: 60px 0;
	color: #fff;
	user-select: none;
`;

const Items = styled.div`
	margin: 0 auto;
	display: grid;
	gap: 1rem;
	@media (min-width: 380px) {
		grid-template-columns: repeat(2, 1fr);
	}
	@media (min-width: 620px) {
		grid-template-columns: repeat(3, 1fr);
	}
	@media (min-width: 769px) {
		grid-template-columns: repeat(4, 1fr);
	}
	@media (min-width: 1200px) {
		grid-template-columns: repeat(8, 1fr);
	}
`;
const SkillWrapper = styled.div`
	padding: 20px;
	text-align: center;
`;

export const Skills = () => {
	const { skillsTitle, imgAlt } = titles;
	return (
		<SkillsWrapper>
			<SectionTitle title={skillsTitle} color="#fff" />
			<Items>
				{skillItems.map(({ title, logo }) => (
					<SkillWrapper key={title}>
						<img src={logo} width="100" alt={imgAlt} />
						<h4>{title}</h4>
					</SkillWrapper>
				))}
			</Items>
		</SkillsWrapper>
	);
};
