import {
	TStreetIcon,
	NewsBossIcon,
	SpokesIcon,
	USNewsIcon,
	SMEIcon,
	PDFEditorIcon,
	IFMIcon,
	PamonoIcon,
	CMSIcon,
	EnergyIcon,
} from 'images';

export const projectsFunnyConfig = [
	{
		className: 'row-1',
		style: {
			padding: '61px 0 0 132px',
		},
		linkStyle: {
			width: '57px',
			height: '56px',
		},
		items: [
			{
				icon: TStreetIcon,
				title: 'TStreet',
				style: {
					marginRight: '22px',
				},
			},
			{
				icon: NewsBossIcon,
				title: 'NewsBoss',
			},
		],
	},
	{
		className: 'row-2',
		style: {
			padding: '19px 0 0 50px',
		},
		linkStyle: {
			width: '57px',
			height: '86px',
		},
		items: [
			{
				icon: SpokesIcon,
				title: '99Spokes',
				style: {
					marginRight: '22px',
				},
			},
			{
				icon: USNewsIcon,
				title: 'USNews',
				style: {
					marginRight: '23px',
				},
			},
			{
				icon: SMEIcon,
				title: 'SME',
				style: {
					marginRight: '22px',
				},
			},
			{
				icon: PDFEditorIcon,
				title: 'PDF Editor',
			},
		],
	},
	{
		className: 'row-3',
		style: {
			padding: '75px 0 0 29px',
		},
		linkStyle: {
			width: '44px',
			height: '85px',
		},
		items: [
			{
				icon: IFMIcon,
				title: 'IFM',
				style: {
					marginRight: '20px',
				},
			},
			{
				icon: PamonoIcon,
				title: 'Pamono',
			},
			{
				icon: EnergyIcon,
				title: 'Energy',
				style: {
					margin: '0px 19px 0px 113px',
				},
			},
			{
				icon: CMSIcon,
				title: 'CMS',
			},
		],
	},
];
