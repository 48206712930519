import styled, { withTheme } from 'styled-components';
import { themePropTypes } from 'propTypes';
import PropTypes from 'prop-types';
import { Breakpoint } from 'react-socks';
import { SvgIcon } from 'components';
import { FooterBg } from 'images';
import { ReactComponent as FBIcon } from 'images/svgIcons/fb-icon.svg';
import { ReactComponent as LinkedInIcon } from 'images/svgIcons/linkedin.svg';
import { titles } from 'texts';

const FooterWrapper = styled.footer`
	flex-grow: 0;
	background-color: ${(props) => props.theme.colors.lightGreen};
	background-image: url(${FooterBg});
`;

const Container = styled.div`
	max-width: 1200px;
	margin: 0 auto;
`;

const FooterContent = styled.div`
	padding: 15px 0;
	display: flex;
	flex-direction: row;
	font-family: 'ProximaNovaBold';
	@media (max-width: 1230px) {
		padding: 15px;
	}
`;

const Copyrights = styled.div`
	flex: 1;
	text-align: right;
	@media (max-width: 769px) {
		text-align: center;
	}
`;

const CopyParagraph = styled.p`
	font-size: 14px;
	color: #fff;
`;

const SiteUrl = styled.a`
	color: #fff;
	text-decoration: none;
	&:hover {
		text-shadow: 0 1px 1px #000;
	}
`;

const NetworkLink = styled.a`
	color: #486388;
	text-decoration: none;
	display: inline-block;
	font-size: 32px;
	margin-left: 10px;
	&:hover {
		color: #fff;
	}
`;

const networkLinksConfig = [
	{ href: 'https://www.linkedin.com/in/a-hetman/', icon: LinkedInIcon },
	{ href: 'https://www.facebook.com/anastasia.hetman.9/', icon: FBIcon },
];

const Footer = ({ theme, CVBtnElement }) => {
	return (
		<FooterWrapper>
			<Container>
				<FooterContent>
					<Breakpoint medium up>
						<CVBtnElement />
					</Breakpoint>
					<Copyrights>
						{networkLinksConfig.map(({ href, icon }) => (
							<NetworkLink href={href} target="_blank" rel="noreferrer" key={href}>
								<SvgIcon
									Icon={icon}
									fill={theme.colors.slateBlue}
									hoverFill="#fff"
									width="28px"
									height="28px"
								/>
							</NetworkLink>
						))}
						<CopyParagraph>
							{titles.copyrightsTitle}
							<SiteUrl href="https://ahetman.com.ua">ahetman.com.ua</SiteUrl>
						</CopyParagraph>
					</Copyrights>
				</FooterContent>
			</Container>
		</FooterWrapper>
	);
};

export default withTheme(Footer);

Footer.propTypes = {
	theme: themePropTypes,
	CVBtnElement: PropTypes.any,
};
