import styled from 'styled-components';
import PropTypes from 'prop-types';
import { SectionTitle, HoverBox } from 'components';
import { titles } from 'texts';
import { projectSlides } from 'configs';

const Wrapper = styled.div`
	background-color: ${(props) => props.theme.colors.slateBlue};
	padding: 60px 0 120px;
	user-select: none;
`;

const Items = styled.div`
	display: grid;
	grid-template-columns: repeat(3, 1fr);
`;

const ProjectItem = styled.div`
	height: 250px;
	cursor: pointer;
	overflow: hidden;
	position: relative;
	background-size: cover;
	background-position-x: center;
`;

export const ProjectsGrid = ({ handleClickProject }) => (
	<Wrapper>
		<SectionTitle title={titles.projectsTitleMobile} color="white" />
		<Items>
			{Object.entries(projectSlides)
				.slice(0, 9)
				.map(([title, { bgImg }]) => (
					<ProjectItem
						key={title}
						style={{
							backgroundImage: `url(${bgImg})`,
						}}
						onClick={() => handleClickProject(title)}
					>
						<HoverBox />
					</ProjectItem>
				))}
		</Items>
	</Wrapper>
);

ProjectsGrid.propTypes = {
	handleClickProject: PropTypes.func.isRequired,
};
