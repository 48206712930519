import {
	SpokesSlide,
	USNewsSlide,
	PDFSlide,
	IFMSlide,
	PamonoSlide,
	CMSSlide,
	EMSSlide,
	TStreetSlide,
	NBSlide,
	SMESlide,
} from 'images';

export const projectSlides = {
	'99Spokes': {
		bgImg: SpokesSlide,
		title: '99Spokes',
		paragraphs: [
			'99Spokes is an awesome service for cyclists to navigate the complex world of bike buying. There are over 91.000 regularly updated bikes on the site to search and compare, data services, articles, reviews, and new releases.',
			'Personally, 99Spokes is a point where two of my passions have met - bikes and web development. So this is a great experience! Furthermore, I admire the technology choice and the code quality of the project.',
		],
		technologies: [
			'NodeJS',
			'Puppeteer',
			'ReactJS',
			'NextJS',
			'React Query',
			'GraphQL',
			'Webpack',
			'YARN',
			'TypeScript',
			'Google/OpenAI APIs',
		],
		siteLink: 'https://99spokes.com/en-EU',
		siteName: '99spokes.com',
	},
	USNews: {
		bgImg: USNewsSlide,
		title: 'US NEWS',
		paragraphs: [
			'A multifaceted digital media company that publishes independent reporting, rankings, data journalism, and advice and has earned the trust of readers and users for nearly 90 years. USNews.com platforms include Education, Health, Money, Travel, Cars, News, and 360 Reviews.',
			'My role on the project: front-end engineer at Ads department. Working on front-end repositories and integration of related platforms such as Prebid, Permuitive, Google Ad Manager, Tealium, etc.',
		],
		technologies: [
			'ReactJS',
			'Redux, Redux-Saga',
			'GraphQL',
			'Styled-components',
			'Webpack, YARN, NPM',
			'CI/CD: GitLab, Jenkins',
			'Dev environment: NodeJS',
			'Tests: chai, mocha',
		],
		siteLink: 'https://www.usnews.com/',
		siteName: 'usnews.com',
	},
	SME: {
		bgImg: SMESlide,
		title: 'SMART MEDIA EDITOR',
		paragraphs: [
			'Smart Media Editor - a powerful photoshop-like online editor that is built specifically for churches, so that a pastor and his team can create presentations without all the manual work.',
			'The project has the modular structure that consists of several packages including the renderer and could be potentially integrated into other Faithlife products.',
			'The project is based on the Canvas and exactly the Canvas makes things tricky and sometimes mindblowing but at the same time - pretty interesting and challenging. This is the project to use classes, inheritance, to dive deeply into the library code (we used FabricJS here) and to implement design patterns (a Factory Method is implemented here).',
		],
		technologies: ['ReactJS', 'Canvas', 'FabricJS', 'Styled Components', 'GIT, YARN, webpack'],
		siteLink: 'https://proclaim.logos.com/features/smart-media',
		siteName: 'Faithlife Proclaim',
	},
	'PDF Editor': {
		bgImg: PDFSlide,
		title: 'DOCUMENTS HELPER',
		paragraphs: [
			'The online PDF editor is the SPA, that allows users to edit PDF files, using different online tools. The app is easy to use but it is complicated in development because of huge amount of calculations, the work with coordinates, svg format, different kinds of events.',
		],
		technologies: [
			'HTML5/SCSS',
			'ReactJS (hooks)',
			'Redux, Redux thunk',
			'TypeScript',
			'PDF JS',
			'GIT, YARN, Axios',
		],
		siteLink: 'https://documentshelper.com/',
		siteName: 'documentshelper.com',
	},
	IFM: {
		bgImg: IFMSlide,
		title: 'INTERFORCE MARKETING',
		paragraphs: [
			'Interforce Marketing is a full-service automotive marketing agency offering loyalty marketing solutions to automotive dealerships and manufacturers.',
			'The service has a lot of complicated functionality, for example, the scedule that is based on appointments information and a user role with possibility to check statistics, to show different kinds of reports, to add/edit/remove events and a lot of other stuff.',
		],
		technologies: [
			'ReactJS',
			'Redux, Redux thunk',
			'Redux Form',
			'GraphQL',
			'Intl',
			'GIT, NPM, webpack',
			'HTML5/SCSS',
		],
		siteLink: 'https://interforcemarketing.com/en/',
		siteName: 'interforcemarketing.com',
	},
	Pamono: {
		bgImg: PamonoSlide,
		title: 'PAMONO MOBILE APP',
		paragraphs: [
			'The internet-shop mobile application that is specializing in distinctive design objects and the stories behind them.',
			'I’ve taken part in the implementation of Log In / Sign Up services and Wish List, added the payment screens and the design filter functionality.',
		],
		technologies: [
			'React Native',
			'Redux, Redux thunk, Redux Form',
			'i18next',
			'GIT, YARN, Axios',
		],
		siteLink: '',
		siteName: '',
	},
	Energy: {
		bgImg: EMSSlide,
		title: 'ENERGY MOTOSERVICE',
		paragraphs: ['The nice-looking landing page made for Energy Motoservice, Kharkiv.'],
		technologies: ['HTML5', 'CSS3', 'Bootstrap 3', 'Responsive Design'],
		siteLink: 'https://energymotoservice.com.ua/',
		siteName: 'energymotoservice.com.ua',
	},
	CMS: {
		bgImg: CMSSlide,
		title: 'Wordpress 2018 / Drupal 2010-2012 Projects',
		paragraphs: [
			'ActionSpot, App4Mission, ArtTorg, Babled.net are Wordpress Projects. Some styling/content changes, refactoring, adding of new functionalities were made here.',
			'Arbudprom, GlobalTrade, Megastroyproject, Metizcomplect, RTK-steel, Lateya, Steelcon, Eurotrade are the Drupal Projects: metal rolling catalogues and corporate websites for metal-trading companies. I’ve created websites with CMS Drupal, developed Drupal’s themes according to the technical specs, made markup and styling, added the content. Also, I`ve taken part in the development and maintenance of the corporate web-portal metalika.ua.',
		],
		technologies: ['Wordpress', 'Drupal 7', 'XHTML', 'CSS', 'JQuery'],
		siteLink: 'https://www.metalika.ua/',
		siteName: 'metalika.ua',
	},
	TStreet: {
		bgImg: TStreetSlide,
		title: 'TStreet',
		paragraphs: [
			'Effective Language Learning: You can learn a lot from reading books in your target language.',
			'My role at the project: I’ve built the project structure and components, made routing, added author/reader/admin roles, wrote API requests, made a lot of services according to back-end endpoints: sign in/sign up, forget password/reset password, upload/update/filter/delete books, the logic for admin section: approval/decline/comment author’s books, added pagination, used Local Storage, made markup and styling according to Photoshop mockup, added loader/modals and other UI elements.',
		],
		technologies: [
			'HTML5/SCSS',
			'ReactJS',
			'Redux, Redux-Saga',
			'GIT, YARN, Axios',
			'Material Design',
		],
		siteLink: '',
		siteName: '',
	},
	NewsBoss: {
		bgImg: NBSlide,
		title: 'NewsBoss',
		paragraphs: [
			'The service helps to record stories. I’ve added functionality for add/edit/copy scripts, made markup and styling according to Photoshop mockup.',
		],
		technologies: [
			'HTML5/SCSS',
			'ReactJS',
			'Redux, Redux-Saga',
			'GIT, YARN, Axios',
			'Material Design',
		],
		siteLink: '',
		siteName: '',
	},
};
