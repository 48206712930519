import styled, { withTheme } from 'styled-components';
import PropTypes from 'prop-types';
import { themePropTypes } from 'propTypes';

const Title = styled.h2`
	font-family: 'RupsterScript';
	text-align: center;
	margin-bottom: 1em;
`;

const SectionTitle = ({ theme, title, color }) => {
	return <Title style={{ color: color || theme.colors.slateBlue }}>{title}</Title>;
};

export default withTheme(SectionTitle);

SectionTitle.propTypes = {
	title: PropTypes.string,
	color: PropTypes.string,
	theme: themePropTypes,
};
