import { setDefaultBreakpoints, BreakpointProvider } from 'react-socks';
import { Root } from './components';
import { ThemeProvider } from 'styled-components';
import { COLORS } from './constants';
import './fonts/arial_bold/ArialBold.ttf';
import './fonts/proxima_nova_bold/proxima_nova_bold.ttf';
import './fonts/proxima_nova_light/proxima_nova_light.ttf';
import './fonts/proxima_nova_regular/proxima_nova_regular.ttf';
import './fonts/rupster_script_free/rupster_script_free.ttf';

setDefaultBreakpoints([{ small: 0 }, { medium: 769 }, { large: 1200 }]);

function App() {
	return (
		<ThemeProvider theme={{ colors: COLORS }}>
			<BreakpointProvider>
				<Root />
			</BreakpointProvider>
		</ThemeProvider>
	);
}

export default App;
