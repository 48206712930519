import styled from 'styled-components';
import { Hedgehog } from 'images';
import { CV } from 'images/certificates';
import { ReactComponent as Download } from 'images/svgIcons/downloadBtn.svg';

const CVWrapper = styled.div`
	font-size: 22px;
	padding-top: 7px;
	background-image: url(${Hedgehog});
	background-size: contain;
	background-repeat: no-repeat;
	width: 140px;
	height: 100px;
	position: absolute;
	bottom: 10px;
	overflow: hidden;
	margin-left: 40px;
`;

const CVLink = styled.a`
	font-family: 'RupsterScript';
	color: ${(props) => props.theme.colors.orange};
	display: block;
	width: 100px;
	text-align: center;
	fill: ${(props) => props.theme.colors.orange};
	transition: 0.5s;
	&:hover {
		color: white;
		fill: white;
	}
`;

const Box = styled.div`
	width: 150px;
	position: relative;
	bottom: 30px;
`;

const CVSpan = styled.span`
	position: relative;
	bottom: 40px;
	left: 7px;
`;

export const CVBtnFunny = () => (
	<CVWrapper>
		<CVLink href={CV} target="_blank">
			<Box>
				<Download />
			</Box>
			<CVSpan>CV</CVSpan>
		</CVLink>
	</CVWrapper>
);
