import styled, { withTheme } from 'styled-components';
import PropTypes from 'prop-types';
import { CloseButton, SvgIcon } from 'components';
import { ReactComponent as CheckIcon } from 'images/svgIcons/check-square.svg';
import { summary } from 'texts';
import { themePropTypes } from 'propTypes';

const Wrapper = styled.div`
	background-color: white;
	max-width: 50%;
	margin: 0 auto;
	padding: 40px;
	position: relative;
	color: ${(props) => props.theme.colors.grey};
	> p {
		padding: 0.5em 0;
	}
	@media (max-width: 769px) {
		max-width: 90%;
	}
`;

const ListItem = styled.li`
	position: relative;
`;

const ListItemText = styled.span`
	padding-left: 4px;
	position: relative;
	bottom: 1px;
`;

const LocationText = ({ theme, onClose }) => {
	const { locationTitle, locationUl, locationHeadParagraph, locationParagraphs } = summary;
	return (
		<Wrapper>
			<CloseButton onClose={onClose} />
			<h3>{locationTitle}</h3>
			<p>{locationHeadParagraph}</p>
			<ul>
				{locationUl.map((x) => (
					<ListItem key={x}>
						<SvgIcon
							Icon={CheckIcon}
							fill={theme.colors.cadetBlue}
							hoverFill={theme.colors.cadetBlue}
							width="12px"
							height="12px"
						/>
						<ListItemText>{x}</ListItemText>
					</ListItem>
				))}
			</ul>
			{locationParagraphs.map((x) => (
				<p key={x.slice(0, 16)}>{x}</p>
			))}
		</Wrapper>
	);
};

export default withTheme(LocationText);

LocationText.propTypes = {
	onClose: PropTypes.func.isRequired,
	theme: themePropTypes,
};
