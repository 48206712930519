import {
	ReactLogo,
	ReduxLogo,
	NextLogo,
	WebpackLogo,
	GraphQLLogo,
	NodeLogo,
	RESTLogo,
	TypeScriptLogo,
} from 'images';

export const skillItems = [
	{ title: 'ReactJS', logo: ReactLogo },
	{ title: 'Redux', logo: ReduxLogo },
	{ title: 'NextJS', logo: NextLogo },
	{ title: 'TypeScript', logo: TypeScriptLogo },
	{ title: 'GraphQL', logo: GraphQLLogo },
	{ title: 'REST', logo: RESTLogo },
	{ title: 'Webpack', logo: WebpackLogo },
	{ title: 'NodeJS', logo: NodeLogo },
];
