import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Breakpoint } from 'react-socks';
import { Header, HeaderMobile } from 'components';

export const HeaderLayout = ({ handleOpenMenu, isMenuOpened, toggleFunnyMode, handleScroll }) => {
	return (
		<Fragment>
			<Breakpoint small only>
				<HeaderMobile handleOpenMenu={handleOpenMenu} isMenuOpened={isMenuOpened} />
			</Breakpoint>
			<Breakpoint medium up>
				<Header toggleFunnyMode={toggleFunnyMode} handleScroll={handleScroll} />
			</Breakpoint>
		</Fragment>
	);
};

HeaderLayout.propTypes = {
	handleOpenMenu: PropTypes.func,
	isMenuOpened: PropTypes.bool,
	toggleFunnyMode: PropTypes.func,
	handleScroll: PropTypes.func,
};
