import {
	Family1,
	Family2,
	Family3,
	Family4,
	Family5,
	Family6,
	Family7,
	Family8,
	Family9,
	Family10,
	Family11,
	Family12,
	Family13,
	Family14,
	Family15,
	Family16,
	Family17,
	Family18,
	Family19,
} from 'images/family/jpg';
import {
	wpFamily1,
	wpFamily2,
	wpFamily3,
	wpFamily4,
	wpFamily5,
	wpFamily6,
	wpFamily7,
	wpFamily8,
	wpFamily9,
	wpFamily10,
	wpFamily11,
	wpFamily12,
	wpFamily13,
	wpFamily14,
	wpFamily15,
	wpFamily16,
	wpFamily17,
	wpFamily18,
	wpFamily19,
} from 'images/family/webp';

export const familySlides = [
	{ title: '', subTitle: '', src: Family1, srcWebp: wpFamily1 },
	{ title: '', subTitle: '', src: Family2, srcWebp: wpFamily2 },
	{ title: '', subTitle: '', src: Family3, srcWebp: wpFamily3 },
	{ title: '', subTitle: '', src: Family4, srcWebp: wpFamily4 },
	{ title: "Khom'yak", subTitle: 'Carpathians, Ukraine', src: Family5, srcWebp: wpFamily5 },
	{ title: '', subTitle: '', src: Family6, srcWebp: wpFamily6 },
	{
		title: 'Under the Chornohora',
		subTitle: 'Carpathians, Ukraine',
		src: Family7,
		srcWebp: wpFamily7,
	},
	{ title: 'Yep, John Snow was right here))', subTitle: '', src: Family8, srcWebp: wpFamily8 },
	{ title: '', subTitle: '', src: Family9, srcWebp: wpFamily9 },
	{ title: '', subTitle: '', src: Family10, srcWebp: wpFamily10 },
	{
		title: 'Manyava Waterfall',
		subTitle: 'Carpathians, Ukraine',
		src: Family11,
		srcWebp: wpFamily11,
	},
	{ title: '', subTitle: '', src: Family12, srcWebp: wpFamily12 },
	{ title: '', subTitle: '', src: Family13, srcWebp: wpFamily13 },
	{ title: '', subTitle: '', src: Family14, srcWebp: wpFamily14 },
	{ title: '', subTitle: '', src: Family15, srcWebp: wpFamily15 },
	{ title: '', subTitle: '', src: Family16, srcWebp: wpFamily16 },
	{ title: '', subTitle: '', src: Family17, srcWebp: wpFamily17 },
	{ title: 'Shpitsi', subTitle: 'Carpathians, Ukraine', src: Family18, srcWebp: wpFamily18 },
	{ title: '', subTitle: '', src: Family19, srcWebp: wpFamily19 },
];
