import { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ProjectsBg } from 'images';
import { titles } from 'texts';
import { HoverBox } from 'components';
import { projectsFunnyConfig } from 'configs';

const Wrapper = styled.div`
	flex: 0 0 33.33%;
	order: 3;
	position: relative;
`;

const Background = styled.div`
	background-image: url(${ProjectsBg});
	background-repeat: no-repeat;
	background-position: center bottom;
	width: 388px;
	height: 564px;
	margin: 0 auto;
`;

const Title = styled.h2`
	font-family: 'RupsterScript';
	font-size: 34px;
	color: #fff;
	text-align: center;
	padding-top: 10px;
	&:hover {
		text-shadow: 0 1px 1px #000;
	}
`;

const ListItem = styled.li`
	display: inline-block;
`;

const ProjectBox = styled.div`
	position: relative;
`;

const ProjectIcon = styled.img`
	border-radius: 2px;
`;

const ProjectName = styled.span`
	display: block;
	text-align: center;
	font-size: 10px;
	color: #fff;
	line-height: 2em;
`;

export const ProjectsFunny = ({ handleClickProject }) => {
	return (
		<Wrapper>
			<Background>
				{projectsFunnyConfig.map(({ className, style, items, linkStyle }, index) => (
					<Fragment key={className}>
						<ul style={style}>
							{items.map(({ icon, title, style = {} }) => (
								<ListItem
									key={title}
									style={style}
									onClick={() => handleClickProject(title)}
								>
									<ProjectBox style={linkStyle}>
										<ProjectIcon src={icon} />
										<HoverBox />
									</ProjectBox>
									<ProjectName>{title}</ProjectName>
								</ListItem>
							))}
						</ul>
						{index === 0 ?
							<Title>{titles.projectsTitle}</Title>
						:	null}
					</Fragment>
				))}
			</Background>
		</Wrapper>
	);
};

ProjectsFunny.propTypes = {
	handleClickProject: PropTypes.func.isRequired,
};
