import {
	Travel1,
	Travel2,
	Travel3,
	Travel4,
	Travel5,
	Travel6,
	Travel7,
	Travel8,
	Travel9,
	Travel10,
	Travel11,
	Travel12,
	Travel13,
	Travel14,
	Travel15,
	Travel16,
	Travel17,
	Travel18,
	Travel19,
	Travel20,
	Travel21,
} from 'images/traveling/jpg';
import {
	wpTravel1,
	wpTravel2,
	wpTravel3,
	wpTravel4,
	wpTravel5,
	wpTravel6,
	wpTravel7,
	wpTravel8,
	wpTravel9,
	wpTravel10,
	wpTravel11,
	wpTravel12,
	wpTravel13,
	wpTravel14,
	wpTravel15,
	wpTravel16,
	wpTravel17,
	wpTravel18,
	wpTravel19,
	wpTravel20,
	wpTravel21,
} from 'images/traveling/webp';

export const travelingSlides = [
	{
		title: 'Have a Nice Day!',
		subTitle: '...in the middle of nowhere',
		src: Travel1,
		srcWebp: wpTravel1,
	},
	{ title: 'Meteora', subTitle: 'Greece', src: Travel2, srcWebp: wpTravel2 },
	{ title: 'Bâlea Lake (or just fog)', subTitle: 'Romania', src: Travel3, srcWebp: wpTravel3 },
	{ title: 'KRKA Waterfalls', subTitle: 'Croatia', src: Travel4, srcWebp: wpTravel4 },
	{ title: 'Bucharest', subTitle: 'Romania', src: Travel5, srcWebp: wpTravel5 },
	{ title: 'Dubrovnik', subTitle: 'Croatia', src: Travel6, srcWebp: wpTravel6 },
	{ title: 'What a Road!', subTitle: 'Georgia', src: Travel7, srcWebp: wpTravel7 },
	{ title: 'Meteora', subTitle: 'Greece', src: Travel8, srcWebp: wpTravel8 },
	{ title: 'Transfagarash', subTitle: 'Romania', src: Travel9, srcWebp: wpTravel9 },
	{ title: 'Mtskheta', subTitle: 'Georgia', src: Travel10, srcWebp: wpTravel10 },
	{
		title: 'China?.. No, just Poltava City',
		subTitle: 'Ukraine',
		src: Travel11,
		srcWebp: wpTravel11,
	},
	{ title: 'Svaneti', subTitle: 'Georgia', src: Travel12, srcWebp: wpTravel12 },
	{ title: 'Tbilisi', subTitle: 'Georgia', src: Travel13, srcWebp: wpTravel13 },
	{ title: 'Transfagarash', subTitle: 'Romania', src: Travel14, srcWebp: wpTravel14 },
	{ title: 'Dubrovnik', subTitle: 'Croatia', src: Travel15, srcWebp: wpTravel15 },
	{ title: 'Carpathians', subTitle: 'Ukraine', src: Travel16, srcWebp: wpTravel16 },
	{ title: 'Transalpina', subTitle: 'Romania', src: Travel17, srcWebp: wpTravel17 },
	{ title: 'Bicaz', subTitle: 'Romania', src: Travel18, srcWebp: wpTravel18 },
	{ title: 'Meteora Monastery', subTitle: 'Greece', src: Travel19, srcWebp: wpTravel19 },
	{ title: 'Svaneti', subTitle: 'Georgia', src: Travel20, srcWebp: wpTravel20 },
	{ title: 'We love traveling!', subTitle: 'Greece', src: Travel21, srcWebp: wpTravel21 },
];
