import { useRef, useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';

export const Swipable = ({ children, onSwipe }) => {
	const [startY, setStartY] = useState(0);

	const wrapperRef = useRef(null);

	const handleTouchStart = useCallback((e) => {
		if (!wrapperRef.current.contains(e.target)) {
			return;
		}
		setStartY(e.touches[0].clientY);
	}, []);

	const handleTouchEnd = useCallback(
		(e) => {
			if (!wrapperRef.current.contains(e.target)) {
				return;
			}
			const endY = e.changedTouches[0].clientY;

			if (endY > startY) {
				onSwipe();
			}
		},
		[startY, onSwipe]
	);

	useEffect(() => {
		window.addEventListener('touchstart', handleTouchStart);
		window.addEventListener('touchend', handleTouchEnd);

		return () => {
			window.removeEventListener('touchstart', handleTouchStart);
			window.removeEventListener('touchend', handleTouchEnd);
		};
	}, [handleTouchStart, handleTouchEnd]);

	return <div ref={wrapperRef}>{children}</div>;
};

Swipable.propTypes = {
	onSwipe: PropTypes.func.isRequired,
	children: PropTypes.node.isRequired,
};
