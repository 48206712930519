import styled from 'styled-components';
import { SkillsFunny, AboutFunny, ProjectsFunny, ProjectsCarouselWrapper } from 'components';

const ContentDesktopWrapper = styled.main`
	flex-grow: 1;
	max-width: 1200px;
	margin: 0 auto;
`;

const Wrapper = styled.div`
	display: flex;
	position: relative;
`;

export const ContentFunny = () => (
	<ContentDesktopWrapper>
		<Wrapper>
			<SkillsFunny />
			<AboutFunny />
			<ProjectsCarouselWrapper ProjectsElement={ProjectsFunny} />
		</Wrapper>
	</ContentDesktopWrapper>
);
