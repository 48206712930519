import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useWindowSize } from 'hooks';
import { DesktopBg } from 'images';
import { HeaderFunny, ContentFunny, Footer, CVBtnFunny, AnimatedWrapper } from 'components';

const Wrapper = styled.div`
	background-image: url(${DesktopBg});
	background-position: top center;
	background-repeat: no-repeat;
	background-size: cover;
	background-color: ${(props) => props.theme.colors.darkGreen};
	line-height: 1.4;
	display: flex;
	flex-direction: column;
	user-select: none;
`;

export const FunnyModeWrapper = ({ toggleFunnyMode }) => {
	const { width } = useWindowSize();

	useEffect(() => {
		if (width < 1200) {
			toggleFunnyMode(false);
		}
	});

	return (
		<AnimatedWrapper>
			<Wrapper>
				<HeaderFunny toggleFunnyMode={toggleFunnyMode} />
				<ContentFunny />
				<Footer CVBtnElement={CVBtnFunny} />
			</Wrapper>
		</AnimatedWrapper>
	);
};

FunnyModeWrapper.propTypes = {
	toggleFunnyMode: PropTypes.func,
};
