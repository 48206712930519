import styled from 'styled-components';
import { LinkBg } from 'images';

const HoverBoxDiv = styled.div`
	opacity: 0;
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	background-image: url(${LinkBg});
	background-repeat: no-repeat;
	background-position: center;
	background-color: rgba(0, 0, 0, 0.7);
	height: 100%;
	width: 100%;
	border-radius: 2px;
	cursor: pointer;
	&:hover {
		opacity: 1;
		transition: ease-out 0.75s;
	}
`;

export const HoverBox = () => <HoverBoxDiv />;
