import styled, { keyframes } from 'styled-components';
import { PropTypes } from 'prop-types';

const fadeInAnimation = keyframes`
	from { opacity: 0 }
  	to { opacity: 100 }
`;

const Wrapper = styled.div`
	animation-name: ${fadeInAnimation};
	animation-duration: 2s;
	animation-timing-function: ease-in-out;
`;

export const AnimatedWrapper = ({ children }) => <Wrapper>{children}</Wrapper>;

AnimatedWrapper.propTypes = {
	children: PropTypes.any,
};
