import styled, { withTheme } from 'styled-components';
import PropTypes from 'prop-types';
import { ReactComponent as CloseIcon } from 'images/svgIcons/close-circle.svg';
import { SvgIcon } from 'components';
import { themePropTypes } from 'propTypes';

const Button = styled.button`
	background-color: #fff;
	border-radius: 50%;
	width: 30px;
	height: 30px;
	top: 10px;
	right: 10px;
	box-shadow: 1px 1px 2px gray;
	cursor: pointer;
	position: absolute;
	border: none;
`;

const CloseButton = ({ theme, onClose }) => (
	<Button onClick={onClose}>
		<SvgIcon
			Icon={CloseIcon}
			fill={theme.colors.cadetBlue}
			hoverFill={theme.colors.orange}
			width="30px"
			height="30px"
		/>
	</Button>
);

export default withTheme(CloseButton);

CloseButton.propTypes = {
	onClose: PropTypes.func.isRequired,
	theme: themePropTypes,
};
