import { useState, Fragment } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import { SlideModal } from 'components';
import { projectSlides } from 'configs';

export const ProjectsCarouselWrapper = ({ ProjectsElement }) => {
	const [isPopupOpened, setIsPopupOpened] = useState(false);
	const [slideTitle, setSlideTitle] = useState(null);

	const handleClickNext = () => {
		const slideKeys = Object.keys(projectSlides);
		const currentItemIndex = slideKeys.indexOf(slideTitle);
		const nextIndex = currentItemIndex + 1 < slideKeys.length ? currentItemIndex + 1 : 0;
		setSlideTitle(slideKeys[nextIndex]);
	};

	const handleClickPrev = () => {
		const slideKeys = Object.keys(projectSlides);
		const currentItemIndex = slideKeys.indexOf(slideTitle);
		const nextIndex = currentItemIndex - 1 >= 0 ? currentItemIndex - 1 : slideKeys.length - 1;
		setSlideTitle(slideKeys[nextIndex]);
	};

	const handleClickProject = (title) => {
		setSlideTitle(title);
		setIsPopupOpened(true);
	};

	return (
		<Fragment>
			<ProjectsElement handleClickProject={handleClickProject} />
			{isPopupOpened &&
				createPortal(
					<SlideModal
						onClose={() => setIsPopupOpened(false)}
						onClickNext={handleClickNext}
						onClickPrev={handleClickPrev}
						slideData={projectSlides[slideTitle]}
					/>,
					document.body
				)}
		</Fragment>
	);
};

ProjectsCarouselWrapper.propTypes = {
	ProjectsElement: PropTypes.any,
};
