import { useState } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { SlideUpMobile } from 'components';
import { projectSlides } from 'configs';

const ProjectsWrapper = styled.div`
	background-color: ${(props) => props.theme.colors.slateBlue};
`;

export const ProjectsSlideUpWrapper = ({ ProjectsElement }) => {
	const [isSlideUpOpened, setIsSlideUpOpened] = useState(false);
	const [slideTitle, setSlideTitle] = useState(null);

	const handleClickProject = (title) => {
		setSlideTitle(title);
		setIsSlideUpOpened(true);
	};

	return (
		<ProjectsWrapper>
			<ProjectsElement handleClickProject={handleClickProject} />
			{isSlideUpOpened &&
				createPortal(
					<SlideUpMobile
						data={projectSlides[slideTitle]}
						onClose={() => setIsSlideUpOpened(false)}
					/>,
					document.body
				)}
		</ProjectsWrapper>
	);
};

ProjectsSlideUpWrapper.propTypes = {
	ProjectsElement: PropTypes.any,
};
