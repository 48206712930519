import styled, { keyframes } from 'styled-components';
import PropTypes from 'prop-types';
import { ProjectDescription, Swipable } from 'components';

const SlideUpAnimation = keyframes`
	0% { transform: translateY(100%) }
	100% { transform: translateY(0) }
`;

const Outer = styled.div`
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	position: absolute;
`;

const DescriptionWrapper = styled.div`
	position: fixed;
	bottom: 0;
	width: 100%;
	animation-name: ${SlideUpAnimation};
	animation-duration: 1s;
	animation-timing-function: MenuItemnear;
	z-index: 100;
`;

export const SlideUpMobile = ({ data, onClose }) => {
	return (
		<Swipable onSwipe={onClose}>
			<Outer onClick={onClose}>
				<DescriptionWrapper>
					<ProjectDescription
						{...data}
						wrapperStyle={{ padding: '60px 20px 20px' }}
						onClose={onClose}
					/>
				</DescriptionWrapper>
			</Outer>
		</Swipable>
	);
};

SlideUpMobile.propTypes = {
	data: PropTypes.shape({
		title: PropTypes.string,
		paragraphs: PropTypes.arrayOf(PropTypes.string),
		technologies: PropTypes.arrayOf(PropTypes.string),
		siteLink: PropTypes.string,
		siteName: PropTypes.string,
	}),
	onClose: PropTypes.func,
};
