import { Fragment, useState } from 'react';
import { FunnyModeWrapper, RegularModeWrapper } from 'components';

export const Root = () => {
	const [isFunnyMode, setIsFunnyMode] = useState(false);

	return (
		<Fragment>
			{isFunnyMode ?
				<FunnyModeWrapper toggleFunnyMode={setIsFunnyMode} />
			:	<RegularModeWrapper toggleFunnyMode={setIsFunnyMode} />}
		</Fragment>
	);
};
