import styled from 'styled-components';
import { mountainSlides, familySlides, cyclingSlides, travelingSlides } from 'configs';
import { SectionTitle, AboutMarquee } from 'components';
import { titles, aboutDescription } from 'texts';

const AboutWrapper = styled.div`
	background-color: ${(props) => props.theme.colors.lightGreen};
	padding-top: 60px;
	user-select: none;
`;

const SliderWrapper = styled.section`
	color: ${(props) => props.theme.colors.grey};
`;

const Title = styled.h3`
	text-transform: uppercase;
	text-align: center;
`;

const Description = styled.p`
	padding: 20px 20px 0;
	text-align: justify;
	max-width: 800px;
	margin: 0 auto;
	@media (max-width: 769px) {
		max-width: 100%;
	}
`;

const slidersConfig = [
	{ title: 'Family', description: aboutDescription.family, slides: familySlides },
	{ title: 'Mountains', description: aboutDescription.mountains, slides: mountainSlides },
	{ title: 'Cycling', description: aboutDescription.cycling, slides: cyclingSlides },
	{ title: 'Traveling', description: aboutDescription.traveling, slides: travelingSlides },
];

export const About = () => {
	return (
		<AboutWrapper>
			<SectionTitle title={titles.aboutTitle} />
			{slidersConfig.map(({ title, description, slides }) => (
				<SliderWrapper key={title}>
					<Title>{title}</Title>
					<Description dangerouslySetInnerHTML={{ __html: description }} />
					<AboutMarquee slides={slides} />
				</SliderWrapper>
			))}
		</AboutWrapper>
	);
};
