import styled from 'styled-components';
import PropTypes from 'prop-types';
import { ContactsBg } from 'images';
import { titles } from 'texts';
import { ReactComponent as ModeBtn } from 'images/svgIcons/mode.svg';

const HeaderLargeWrapper = styled.header`
	display: flex;
	padding-top: 5px;
	width: 100%;
	max-width: 1200px;
	margin: 0 auto;
	justify-content: space-between;
`;

const HeaderLeft = styled.div`
	padding: 10px 0 0 20px;
	display: flex;
	flex-direction: row;
`;

const HeaderRight = styled.div`
	background-image: url(${ContactsBg});
	background-repeat: no-repeat;
	background-size: contain;
	font-family: 'ProximaNovaBold';
	padding: 25px 20px 0 0;
	text-align: center;
`;

const Logo = styled.div`
	font-family: 'RupsterScript';
	color: #32aeda;
	width: 341px;
	text-shadow:
		#fff 2px 0 0px,
		#fff 2px 0px,
		#fff -2px 0 0px,
		#fff 0 -2px 0px,
		#1a97ad 2px 5px 5px;
`;

const LogoLink = styled.a`
	color: #32aeda;
	text-decoration: none;
	font-size: 40px;
`;

const LogoSubject = styled.h2`
	font-size: 30px;
	margin-top: 15px;
`;

const BtnWrapper = styled.div`
	padding-left: 304px;
	position: relative;
`;

const ModeButton = styled.button`
	width: 130px;
	background-color: transparent;
	border: none;
	cursor: pointer;
	> svg {
		fill: white;
		position: relative;
		bottom: 5px;
		transition: 0.5s;
		&:hover {
			fill: ${(props) => props.theme.colors.grey};
		}
	}
`;

const MailTitle = styled.p`
	color: ${(props) => props.theme.colors.darkOrange};
	font-size: 24px;
`;

const MailLink = styled.a`
	color: ${(props) => props.theme.colors.orange};
	font-size: 20px;
	text-decoration: none;
	padding: 0 20px;
	&:hover {
		text-shadow: 0 1px 1px #bdbdbd;
	}
`;

export const HeaderFunny = ({ toggleFunnyMode }) => {
	const { name, role, emailTitle, email } = titles;
	return (
		<HeaderLargeWrapper>
			<HeaderLeft>
				<Logo>
					<LogoLink href="#">{name}</LogoLink>
					<LogoSubject>{role}</LogoSubject>
				</Logo>
				<BtnWrapper>
					<ModeButton onClick={() => toggleFunnyMode(false)}>
						<ModeBtn />
					</ModeButton>
				</BtnWrapper>
			</HeaderLeft>
			<HeaderRight>
				<MailTitle>{emailTitle}</MailTitle>
				<MailLink href={`mailto:${email}`}>{email}</MailLink>
			</HeaderRight>
		</HeaderLargeWrapper>
	);
};

HeaderFunny.propTypes = {
	toggleFunnyMode: PropTypes.func,
};
