import PropTypes from 'prop-types';
import styled from 'styled-components';
import { titles } from 'texts';
import { SectionTitle, SvgIcon } from 'components';
import { projectSlides } from 'configs';
import { ReactComponent as NextIcon } from 'images/svgIcons/icon-next-triangle.svg';

const ProjectsWrapper = styled.div`
	background-color: ${(props) => props.theme.colors.slateBlue};
	padding-top: 60px;
`;

const ProjectItem = styled.div`
	background-color: white;
	cursor: pointer;
	display: flex;
	flex-direction: column;
`;

const ProjectFooter = styled.div`
	padding: 20px;
	display: flex;
	align-items: center;
	background-color: ${(props) => props.theme.colors.slateBlue};
`;

const ProjectTitle = styled.p`
	color: white;
	font-size: 24px;
	font-family: 'ProximaNovaBold';
	text-transform: uppercase;
	@media (max-width: 550px) {
		font-size: 18px;
	}
`;

const IconBox = styled.div`
	width: 20px;
	height: 20px;
	padding-left: 20px;
`;

export const ProjectsMobile = ({ handleClickProject }) => {
	return (
		<ProjectsWrapper>
			<SectionTitle title={titles.projectsTitleMobile} color="white" />
			<div>
				{Object.entries(projectSlides).map(([key, { bgImg, title }]) => (
					<ProjectItem key={title} onClick={() => handleClickProject(key)}>
						<img src={bgImg} alt={titles.imgAlt} />
						<ProjectFooter>
							<ProjectTitle>{title}</ProjectTitle>
							<IconBox>
								<SvgIcon
									Icon={NextIcon}
									fill="white"
									hoverFill="white"
									width="18px"
									height="18px"
								/>
							</IconBox>
						</ProjectFooter>
					</ProjectItem>
				))}
			</div>
		</ProjectsWrapper>
	);
};

ProjectsMobile.propTypes = {
	handleClickProject: PropTypes.func,
};
