import PropTypes from 'prop-types';
import { Breakpoint } from 'react-socks';
import styled from 'styled-components';
import { titles } from 'texts';
import { MenuRegular, HeaderIconLinks } from 'components';

const Wrapper = styled.div`
	background-color: ${(props) => props.theme.colors.lightGreen};
`;

const Content = styled.div`
	max-width: 800px;
	margin: 0 auto;
	display: flex;
	justify-content: space-between;
	padding: 20px;
	@media (max-width: 1200px) {
		padding: 20px 40px;
	}
`;
const ModeButton = styled.button`
	background-color: ${(props) => props.theme.colors.orange};
	border: none;
	border-bottom: 3px solid ${(props) => props.theme.colors.darkOrange};
	border-radius: 5px;
	outline: none;
	font-family: 'ProximaNovaBold';
	line-height: 3em;
	cursor: pointer;
	color: white;
	padding: 0 10px;
	&:hover {
		box-shadow: 0 1px 4px gray;
	}
`;

export const Header = ({ toggleFunnyMode, handleScroll }) => (
	<Wrapper>
		<Content>
			<MenuRegular handleScroll={handleScroll} />
			<Breakpoint large only>
				<ModeButton onClick={() => toggleFunnyMode(true)}>
					{titles.switchToFunnyModeBtn}
				</ModeButton>
			</Breakpoint>
			<Breakpoint medium only>
				<HeaderIconLinks />
			</Breakpoint>
		</Content>
	</Wrapper>
);

Header.propTypes = {
	toggleFunnyMode: PropTypes.func,
	handleScroll: PropTypes.func,
};
