import { useState, useRef, useEffect } from 'react';
import { createPortal } from 'react-dom';
import styled, { withTheme } from 'styled-components';
import { PropTypes } from 'prop-types';
import {
	HeaderLayout,
	Footer,
	Summary,
	Skills,
	ProjectsLayout,
	MenuMobile,
	SvgIcon,
	Other,
	About,
	CVBtnFooter,
	AnimatedWrapper,
} from 'components';
import { ReactComponent as UpIcon } from 'images/svgIcons/icon-up.svg';
import { themePropTypes } from 'propTypes';

const Header = styled.header``;

const Content = styled.main`
	background-color: ${(props) => props.theme.colors.bgBlue};
`;

const UpButtonWrapper = styled.div`
	position: fixed;
	bottom: 20px;
	right: 20px;
	cursor: pointer;
`;

const RegularModeWrapper = ({ toggleFunnyMode, theme }) => {
	const [isMenuOpened, setIsMenuOpened] = useState(false);
	const [isHeaderInView, setIsHeaderInView] = useState(true);

	const topRef = useRef(null);
	const skillsRef = useRef(null);
	const projectsRef = useRef(null);
	const aboutRef = useRef(null);
	const otherRef = useRef(null);

	function getRef(refName) {
		switch (refName) {
			case 'top':
				return topRef;
			case 'projects':
				return projectsRef;
			case 'skills':
				return skillsRef;
			case 'about':
				return aboutRef;
			case 'other':
				return otherRef;
			default:
				return topRef;
		}
	}

	const handleScroll = (refName) => {
		const ref = getRef(refName);
		ref.current.scrollIntoView({ behavior: 'smooth' });
	};

	const checkHeaderInView = () => {
		const rect = topRef.current.getBoundingClientRect();
		setIsHeaderInView(rect.top < window.innerHeight && rect.bottom >= 0);
	};

	useEffect(() => {
		document.addEventListener('scroll', checkHeaderInView);
		return () => {
			document.removeEventListener('scroll', checkHeaderInView);
		};
	}, []);

	return (
		<AnimatedWrapper>
			<Header ref={topRef}>
				<HeaderLayout
					handleOpenMenu={setIsMenuOpened}
					isMenuOpened={isMenuOpened}
					toggleFunnyMode={toggleFunnyMode}
					handleScroll={handleScroll}
				/>
			</Header>
			<Content>
				<section id="summary">
					<Summary />
				</section>
				<section id="skills" ref={skillsRef}>
					<Skills />
				</section>
				<section id="projects" ref={projectsRef}>
					<ProjectsLayout />
				</section>
				<section id="about" ref={aboutRef}>
					<About />
				</section>
				<section id="other" ref={otherRef}>
					<Other />
				</section>
			</Content>
			<Footer CVBtnElement={CVBtnFooter} />
			{!isHeaderInView && (
				<UpButtonWrapper onClick={() => handleScroll('top')}>
					<SvgIcon
						Icon={UpIcon}
						fill={theme.colors.slateBlue}
						hoverFill={theme.colors.slateBlue}
						width="40px"
						height="40px"
					/>
				</UpButtonWrapper>
			)}
			{isMenuOpened &&
				createPortal(
					<MenuMobile handleScroll={handleScroll} handleCloseMenu={setIsMenuOpened} />,
					document.body
				)}
		</AnimatedWrapper>
	);
};

export default withTheme(RegularModeWrapper);

RegularModeWrapper.propTypes = {
	toggleFunnyMode: PropTypes.func,
	theme: themePropTypes,
};
