import {
	Cycling1,
	Cycling2,
	Cycling3,
	Cycling4,
	Cycling5,
	Cycling6,
	Cycling7,
	Cycling8,
	Cycling9,
	Cycling10,
	Cycling11,
	Cycling12,
	Cycling13,
	Cycling14,
	Cycling15,
	Cycling16,
	Cycling17,
	Cycling18,
	Cycling19,
} from 'images/cycling/jpg';
import {
	wpCycling1,
	wpCycling2,
	wpCycling3,
	wpCycling4,
	wpCycling5,
	wpCycling6,
	wpCycling7,
	wpCycling8,
	wpCycling9,
	wpCycling10,
	wpCycling11,
	wpCycling12,
	wpCycling13,
	wpCycling14,
	wpCycling15,
	wpCycling16,
	wpCycling17,
	wpCycling18,
	wpCycling19,
} from 'images/cycling/webp';

export const cyclingSlides = [
	{ title: '', subTitle: '', src: Cycling1, srcWebp: wpCycling1 },
	{ title: '', subTitle: '', src: Cycling2, srcWebp: wpCycling2 },
	{ title: '', subTitle: '', src: Cycling3, srcWebp: wpCycling3 },
	{ title: '', subTitle: '', src: Cycling4, srcWebp: wpCycling4 },
	{ title: '', subTitle: '', src: Cycling5, srcWebp: wpCycling5 },
	{ title: '', subTitle: '', src: Cycling6, srcWebp: wpCycling6 },
	{ title: '', subTitle: '', src: Cycling7, srcWebp: wpCycling7 },
	{ title: '', subTitle: '', src: Cycling8, srcWebp: wpCycling8 },
	{
		title: '',
		subTitle: 'When fairies are out of pixie dust',
		src: Cycling9,
		srcWebp: wpCycling9,
	},
	{ title: '', subTitle: '', src: Cycling10, srcWebp: wpCycling10 },
	{ title: '', subTitle: '', src: Cycling11, srcWebp: wpCycling11 },
	{ title: '', subTitle: '', src: Cycling12, srcWebp: wpCycling12 },
	{ title: '', subTitle: '', src: Cycling13, srcWebp: wpCycling13 },
	{ title: '', subTitle: '', src: Cycling14, srcWebp: wpCycling14 },
	{ title: '', subTitle: '', src: Cycling15, srcWebp: wpCycling15 },
	{ title: '', subTitle: '', src: Cycling16, srcWebp: wpCycling16 },
	{ title: '', subTitle: '', src: Cycling17, srcWebp: wpCycling17 },
	{ title: '', subTitle: '', src: Cycling18, srcWebp: wpCycling18 },
	{ title: '', subTitle: '', src: Cycling19, srcWebp: wpCycling19 },
];
