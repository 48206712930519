import styled from 'styled-components';
import { Apple, Arrow, SkillsBg } from 'images';
import { skillItems, titles } from 'texts';

const SkillsWrapper = styled.div`
	flex: 0 0 33.33%;
	order: 1;
	padding-top: 105px;
	position: relative;
`;

const SkillsList = styled.ul`
	max-width: 399px;
	height: 459px;
	margin: 0 auto;
	background-image: url(${SkillsBg});
	background-repeat: no-repeat;
	background-position: center bottom;
	position: relative;
`;

const SkillsItem = styled.li`
	font-family: 'ArialBold';
	color: #fff;
	background-image: url(${Apple});
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
	font-size: 10px;
	text-align: center;
	display: inline-block;
	box-sizing: border-box;
	position: relative;
	&:first-child {
		padding: 25px 20px 20px;
		left: 20px;
		top: 125px;
	}
	&:nth-child(2) {
		padding: 20px 15px 15px;
	}
	&:nth-child(3) {
		padding: 20px 15px 15px;
	}
	&:nth-child(4) {
		padding: 35px 30px 30px;
		top: 30px;
		left: 30px;
	}
	&:nth-child(5) {
		padding: 25px 20px 20px;
		top: 100px;
	}
	&:nth-child(6) {
		padding: 20px 15px 15px;
		top: 90px;
		left: 90px;
	}
	&:nth-child(7) {
		padding: 25px 20px 20px;
		bottom: 20px;
	}
	&:nth-child(8) {
		padding: 35px 30px 30px;
	}
	&:nth-child(9) {
		max-width: 60px;
		padding: 25px 20px 15px 12px;
		top: 60px;
	}
	&:nth-child(10) {
		max-width: 100px;
		padding: 30px 20px;
		top: 130px;
		right: 20px;
	}
	&:nth-child(11) {
		max-width: 100px;
		padding: 35px 20px 25px;
		top: 60px;
		left: 170px;
	}
	&:nth-child(12) {
		max-width: 120px;
		padding: 45px 20px 35px;
		bottom: -180px;
	}
	&:hover {
		text-shadow: 0 1px 1px #000;
	}
	&::before {
		content: '';
		background-image: none;
	}
`;

const Title = styled.div`
	font-family: 'RupsterScript';
	font-size: 26px;
	color: #fff;
	text-align: center;
	&::after {
		content: '';
		background-image: url(${Arrow});
		width: 52px;
		height: 49px;
		display: inline-block;
	}
	&:hover {
		text-shadow: 0 1px 1px #000;
	}
`;

export const SkillsFunny = () => (
	<SkillsWrapper>
		<SkillsList>
			{Object.values(skillItems).map((skill) => (
				<SkillsItem key={skill}>
					<span dangerouslySetInnerHTML={{ __html: skill }} />
				</SkillsItem>
			))}
		</SkillsList>
		<Title>{titles.treeTitle}</Title>
	</SkillsWrapper>
);
