import { useState } from 'react';
import PropTypes from 'prop-types';

export const SvgIcon = ({ Icon, fill, hoverFill, width, height }) => {
	const [isHovered, setIsHovered] = useState(false);
	return (
		<Icon
			width={width}
			height={height}
			fill={isHovered ? hoverFill : fill}
			onMouseEnter={() => setIsHovered(true)}
			onMouseLeave={() => setIsHovered(false)}
		/>
	);
};

SvgIcon.propTypes = {
	Icon: PropTypes.object,
	fill: PropTypes.string,
	hoverFill: PropTypes.string,
	width: PropTypes.string,
	height: PropTypes.string,
};
